<template>
    <div class="tool planning__body">
        <template v-if="true">
            <h4>Consolidated Upload to Bauer Biz</h4>
            <div class="flex-box flex-column">
                <ReviewOrder v-if="true" :key="reviewOrderKey"/>
            </div>
        </template>
    </div>
</template>

<script>
import ReviewOrder from './uploadbb/ReviewOrder.vue';

export default {
    components: {
        ReviewOrder
    },
    data() {
        return {
            reviewOrderKey: 0,
        };
    },
    beforeDestroy() {
        this.$root.$off('updatedConfig');
        this.$root.$off('saveSuggestedMonthlyBookings');
        this.$root.$off('saveBookings');
    }
    ,
    mounted() {
        const thisInstance = this;
        this.$root.$on('updatedConfig', function () {
            thisInstance.reviewOrderKey += 1;

        })

        this.$root.$on('saveSuggestedMonthlyBookings', function () {
            thisInstance.reviewOrderKey += 1;

        })

        this.$root.$on('saveBookings', function () {
            thisInstance.reviewOrderKey += 1;

        })
    },
};
</script>
<style>
.image {
    margin: auto;
}
</style>

<template>
  <div class="wrap">
    <div v-if="showBar" class="progress-bar-container">
      <div class="progress-bar">
        <div class="progress-fill" :style="{ width: progressPercentage + '%' }"></div>
      </div>
      <div class="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    showBar: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: "Loading...",
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressPercentage() {
      return Math.min(Math.max(this.progress, 0), 100); // Ensure progress is between 0 and 100
    },
  },
};
</script>

<style lang="scss" scoped>
$font-family-primary: "Roboto";
$progress-color: #009acd;
$background-color: #e0e0e0;

.wrap {
  font-family: $font-family-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

.progress-bar {
  width: 500px;
  height: 20px;
  background-color: $background-color;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.progress-fill {
  height: 100%;
  background-color: $progress-color;
  transition: width 0.4s ease-in-out;
}

.text {
  color: $progress-color;
  display: inline-block;
}
</style>
